<template>
  <div class="row">
    <p style="color:white">aergethrjui</p>
  </div>
</template>
<script>

export default {
}
</script>
<style></style>
